import * as Sentry from '@sentry/vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { getAuth, signOut } from 'firebase/auth';

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  const { isBrand } = useUserState();
  const isLoggingOut = ref(false);

  const mutation = useMutation({
    mutationFn: async () => {
      isLoggingOut.value = true;
      const auth = getAuth();

      if (auth?.currentUser) {
        await signOut(auth);
      }

      // Clear Sentry user context
      Sentry.setUser(null);
    },
    onSuccess: async () => {
      if (isBrand.value && typeof window !== 'undefined') {
        // TODO: remove this once the brand side is fully refactored
        // so we can just clear the queries as we do below
        window.location.href = '/login';
      } else {
        // Clear queries before navigation
        await queryClient.invalidateQueries({
          queryKey: ['me'],
          exact: true,
        });

        await navigateTo('/login');
      }
    },
    onError: () => {
      isLoggingOut.value = false;
    },
  });

  const logoutText = computed(() => {
    return isLoggingOut.value ? 'Logging out...' : 'Logout';
  });

  return {
    logout: mutation.mutateAsync,
    isLoggingOut: readonly(isLoggingOut),
    logoutText,
  };
};
